<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/information"
          >-<span v-html="'&nbsp'"></span>资讯<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span> 房屋租赁经纪行业制度</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsDate">
                <div class="year">2021-07</div>
                <div class="day">08</div>
              </div>
              <div class="newsText">房屋租赁经纪行业制度</div>
            </div>
            <div class="news_content">
              <p>
                1998年住房制度改革以来，我国房地产经纪行业快速发展，据不完全统计,全国房地产经纪机构已逾5万家,从业人员超过100万人。二手房买卖市场份额不断扩大,而房屋租赁经纪业务一直都不是大多数房地产经纪企业的主要业务。由于房地产经纪行业准入门槛低、人员流动性大、市场秩序不甚规范,一些经纪机构存在发布虚假信息、协助当事人签订阴阳合同、挪用交易资金、吃差价等不规范行为,总体来看,我国房产中介行业的社会形象并不好。
              </p>
              <br />
              <br />
              <br />
              <p>(一) 经纪机构准入门槛过低还是过高?</p>
              <p>
                普遍认为是当前房地产经纪机构准入门槛过低,导致从业人员素质过低流动性较大。这是因为在准入方面,虽然对房产中介机构要求具备《房地产估价师资格证书》和《房地产经纪人资格证》等方面的要求,但其规定比较笼统,许多并不具有专业水准的人和机构通过挂靠等手段也从事房产经纪业务,造成这一行业从业者素质参差不齐。但也有学者提出这个问题其实是准入门槛过高引「致的。准入门槛过高,尤其是对注册经纪人的人员数量要求高,导致了企业到处找注册经纪人挂靠,无法找到的则逃避备案,无法保证当事人的权益。如据不完全统计,天津市2008年、2009年经核准取得营业执照而未进行备案开展业务的机构总计584家。①来自住建部的统计也显示,全国从事房地产经纪活动的人员超过100万人,其中仅有3.7万人取得了全国房地产经纪人资格,房地产经纪机构(含分支机构)超过5万家,经济发达城市的房地产经纪机构数量较多,如北京市取得营业执照的房地产经纪机构约1.2万家上海约900家。北京1.2万家房地产经纪机构中,仅有5500多家到房地产管理部门备案,备案率不到一半;深圳7800家机构中,仅有1200家备案,备案率只有15%。对房地产经纪人员也没有强制性的资格要求,许多从业人员没有受过正规的职业教育,素质较低,流动性大。
              </p>
              <p>
                当前办理备案的经纪机构比重偏低,普遍存在覆盖率不均衡,准入条件与实际情况脱钩。要求机构必须具备5名房地产注册经纪人的规定,无疑对尚在起步阶段的房地产经纪行业过高,况且目前也没有相应规模的房地产经纪人员队伍来满足需要。
              </p>
              <br />
              <p>
                (二)
                行政管理为主,自律不足我国房地产经纪行业自律薄弱,现行的行政主管模式难见成效。行业自我管理的职能较弱,表现在:房地产经纪市场诚信缺失,公布虚假房源信息揽客,交易资金安全没有保障;骗取中介费、看房费;信息公示不齐全,发布虚假房源信息,以交易为导向,从事大量违法行为,如签订阴阳合同等状况等。
              </p>
              <br />
              <p>
                (三)
                《房地产经纪管理办法》的调整鉴于我国过去十几年来,房地产经纪机构存在各种违规、违法行为,严重损害了当事人的权益,扰乱了市场秩序。为规范房地产经纪活动,2010年10月27日通过了《房地产经纪管理办法》,详细规范了房地产经纪活动主体以及其业务内容。以达到保护房地产交易及经纪活动当事人合法权益,促进房地产市场健康发展的目的。包括:
              </p>
              <p>
                1.
                房屋租赁居间和代理业务的资格准入制度实施房地产经纪机构和从业人员的准人制度,要求经纪机构及其分支机构的从业人员必须达到国家的专业和财务人员数量方可成立,成立后应当及时注册备案。主管部门随后应及时将相关信息向社会公示。房地产经纪人员必须持房地产经纪人和协理资格证上岗,并在其相关业务文件上签名,以确保责任明确
              </p>
              <p>
                2.经纪业务的监督具体包括房地产经纪业务须统一承接,服务报酬由房地产经纪机构统一收取;应公示房地产经纪业务关键信息;实施代收代付资金监管。
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/help1">
                  <img src="../../assets/images/help/help1.jpeg" alt="" />
                  <div class="msg">
                    房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/help2">
                  <img src="../../assets/images/help/help3.jpeg" alt="" />
                  <div class="msg">租房也可变Dream House</div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help3">
                  <img src="../../assets/images/help/help2.png" alt="" />
                  <div class="msg">
                    房东利益如何最大化之合理装修
                  </div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help4">
                  <img src="../../assets/images/help/help4.png" alt="" />
                  <div class="msg">自建房的房屋租赁税计算</div></router-link
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/information/资讯背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
          .newsDate {
            float: left;
            width: 55px;
            height: 55px;
            background-color: #436993;
            color: #fff;
            padding: 6px 2px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            .year {
              font-size: 12px;
            }
            .day {
              font-size: 28px;
            }
          }
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          padding-left: 70px;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
